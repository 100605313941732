import React, { useEffect, useState } from "react";
import { toDateString } from "../../_helpers/utils";
import { fundingService } from "../../_services/funding.service";
import { defaultTo, first, get, orderBy } from "lodash";
import { ubpService, capexService } from "../../_services";

const ProjectDashboard = ({ project }) => {
  const [funding, setFunding] = useState(null);
  const [capex, setCapex] = useState(null);
  const [ubp, setUbp] = useState(null);

  const idProject = defaultTo(project.idProject, 0);
  useEffect(() => {
    if (project) {
      fundingService
        .getByIdProject(idProject)
        .then((fundingData) => {
          setFunding(fundingData);
        })
        .catch(() => setFunding(null));

      capexService.getByIdProject(idProject).then((capexData) => {
        const newestCapex = first(orderBy(capexData, "month", "desc"));
        setCapex(newestCapex);
      });

      ubpService.getByIdProject(idProject).then((ubpData) => {
        const newestubp = first(orderBy(ubpData, "year", "desc"));
        setUbp(newestubp);
      });
    }
  }, [idProject]);

  return (
    <>
      <h4 className="project">
        Project Details: {`${project.idProject} ${project.projectType}`}
      </h4>
      <div id="projectDashboard" className="container-fluid">
        <div className="row">
          <div className={"col-4"} style={{ paddingLeft: 0 }}>
            <table style={{ width: "100%", height: "100%" }}>
              <tbody>
                <tr>
                  <th colSpan="2">Project Development</th>
                </tr>
                <tr>
                  <td width={150}></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Priority</td>
                  <td>{project.projectPriority}</td>
                </tr>
                <tr>
                  <td>Identified</td>
                  <td>{project.projectIdentified}</td>
                </tr>
                <tr>
                  <td>LOI Site</td>
                  <td>{project.projectLOI}</td>
                </tr>
                <tr>
                  <td>Site-Screening</td>
                  <td>{project.projectSite}</td>
                </tr>
                <tr>
                  <td>IPP</td>
                  <td>{project.projectIPP}</td>
                </tr>
                <tr>
                  <td>IPP Date</td>
                  <td>{toDateString(project.projectIPPDate)}</td>
                </tr>
                <tr>
                  <td>Site-Scoping</td>
                  <td>{project.projectSiteScoping}</td>
                </tr>
                <tr>
                  <td>Supply-Scoping</td>
                  <td>{project.projectSupply}</td>
                </tr>
                <tr>
                  <td>Demand-Scoping</td>
                  <td>{project.projectDemand}</td>
                </tr>
                <tr>
                  <td>Funding-Scoping</td>
                  <td>{project.fundingStatus}</td>
                </tr>
                <tr>
                  <td>BC final</td>
                  <td>{project.projectBC}</td>
                </tr>
                <tr>
                  <td>PID</td>
                  <td>{project.projectPID}</td>
                </tr>
                <tr>
                  <td>PID Date</td>
                  <td>{toDateString(project.projectPIDDate)}</td>
                </tr>
                <tr>
                  <td>FID</td>
                  <td>{project.projectFID}</td>
                </tr>
                <tr>
                  <td>FID Date</td>
                  <td>{toDateString(project.projectFIDDate)}</td>
                </tr>
                <tr>
                  <td>KickoffDate</td>
                  <td>{toDateString(project.projectKickoffDate)}</td>
                </tr>
                <tr>
                  <td>Lead PD</td>
                  <td>{project.projectLeadPD}</td>
                </tr>
                <tr>
                  <td colSpan="2">Comment</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#e1e1e1" }} colSpan="2">
                    {project.comment}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={"col-4"} style={{ paddingLeft: 2, paddingRight: 0 }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ display: "none" }}>
                  <td width={120}></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="2">Construction</th>
                </tr>
                <tr>
                  <td>Planning</td>
                  <td>{project.projectPlanning}</td>
                </tr>
                <tr>
                  <td>Permit</td>
                  <td>{project.projectPermit}</td>
                </tr>
                <tr>
                  <td>Construction</td>
                  <td>{project.projectConstruction}</td>
                </tr>
                <tr>
                  <td>Technology</td>
                  <td>{project.projectTechnology}</td>
                </tr>
                <tr>
                  <td>Commissioning</td>
                  <td>{project.projectCommissioning}</td>
                </tr>
                <tr>
                  <td>Golive Date</td>
                  <td>{toDateString(project.projectGoliveDate)}</td>
                </tr>
                <tr>
                  <td>Lead PM</td>
                  <td>{project.projectLeadPM}</td>
                </tr>
                <tr>
                  <td colSpan="2">Comment</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#e1e1e1" }} colspan="2">
                    {project.commentConstruction}
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">Sales</th>
                </tr>
                <tr>
                  <td>Agreed Start Client</td>
                  <td>{toDateString(project.projectAgreedStartClientDate)}</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>{project.projectCustomer}</td>
                </tr>
                <tr>
                  <td>Type of Demand Security</td>
                  <td>{project.projectTypeOfDemandSecurity}</td>
                </tr>
                <tr>
                  <td>Sales Price Clause</td>
                  <td>{project.projectSalesPriceClause}</td>
                </tr>
                <tr>
                  <th colSpan="2">Controlling</th>
                </tr>
                <tr>
                  <td>PIR Date</td>
                  <td>{toDateString(project.projectPIRDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-4" style={{ paddingLeft: 2, paddingRight: 0 }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ display: "none" }}>
                  <td width={120}></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="3">Funding</th>
                </tr>
                <tr>
                  <td>Status</td>
                  <td colSpan="2">{get(funding, "projectStatus", "")}</td>
                </tr>
                <tr>
                  <td>Call</td>
                  <td colSpan="2">{get(funding, "call", "")}</td>
                </tr>
                <tr>
                  <td>Sign/No.</td>
                  <td colSpan="2">{project.sign}</td>
                </tr>
                <tr>
                  <td>Budget</td>
                  <td colSpan="2">
                    {Number(project.budget).toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                </tr>
                <tr>
                  <td>Quota</td>
                  <td colSpan="2">
                    {Number(get(funding, "quota", 0)).toLocaleString("de-DE", {
                      style: "percent",
                    })}
                  </td>
                </tr>
                <tr>
                  <td>Start</td>
                  <td colSpan="2">
                    {funding ? toDateString(funding.start) : ""}
                  </td>
                </tr>
                <tr>
                  <td>End</td>
                  <td colSpan="2">
                    {funding ? toDateString(funding.end) : ""}
                  </td>
                </tr>
                <tr>
                  <td>Contact</td>
                  <td colSpan="2">{get(funding, "contact1", "")}</td>
                </tr>
                <tr>
                  <td>Zweckbind.</td>
                  <td colSpan="2">{get(funding, "appropriation", "")}</td>
                </tr>
                <tr>
                  <td>End Zweckb.</td>
                  <td colSpan="2">
                    {toDateString(project.appropriationDeadline)}
                  </td>
                </tr>
                <tr>
                  <td>Cashflow</td>
                  <td colSpan="2">
                    {Number(project.cashflow).toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Comment</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#f0f0f0" }} colSpan="3">
                    {project.commentFunding}
                  </td>
                </tr>
                <tr>
                  <td className="update">Last Update</td>
                  <td className="update" colSpan="2">
                    {funding
                      ? toDateString(funding.lastUpdateDate, {
                          hour: "numeric",
                          minute: "numeric",
                        })
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="update">Updated by</td>
                  <td className="update" colSpan="2">
                    {get(funding, "lastUpdateUser", "")}
                  </td>
                </tr>
                <tr>
                  <th colspan="3">CAPEX</th>
                </tr>
                <tr>
                  <td></td>
                  <td>&euro;</td>
                  <td>Date</td>
                </tr>
                {ubp && (
                  <tr>
                    <td>UBP</td>
                    <td style={{ textAlign: "right" }}>
                      {Number(ubp.betrag).toLocaleString("de-DE", {
                        maximumFractionDigits: 0,
                      })}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {toDateString(ubp.year)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>FID</td>
                  <td style={{ textAlign: "right" }}>
                    {Number(project.projetCAPEXFID).toLocaleString("de-DE", {
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td></td>
                </tr>
                {capex && (
                  <>
                    <tr>
                      <td>Last Forecast</td>
                      <td style={{ textAlign: "right" }}>
                        {Number(capex.value).toLocaleString("de-DE", {
                          maximumFractionDigits: 0,
                        })}
                      </td>
                      <td rowSpan="4" style={{ textAlign: "center" }}>
                        {toDateString(capex.month)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "20px" }}>Thereof Invoices</td>
                      <td style={{ textAlign: "right" }}>
                        {Number(capex.invoices).toLocaleString("de-DE", {
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "20px" }}>Thereof Orders</td>
                      <td style={{ textAlign: "right" }}>
                        {Number(capex.orders).toLocaleString("de-DE", {
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "20px" }}>Thereof Preorders</td>
                      <td style={{ textAlign: "right" }}>
                        {Number(capex.preorders).toLocaleString("de-DE", {
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-8" style={{ paddingLeft: 0 }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td className="update" colSpan="2">
                    Last Update
                  </td>
                  <td className="update" colSpan="2">
                    {toDateString(project.lastUpdateDate, {
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="update" colSpan="2">
                    Updated By
                  </td>
                  <td className="update" colSpan="2">
                    {project.lastUpdateUser}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDashboard;
